.embed-pd {
  &__view-toggle-icon > path {
    color: $secondary !important;
  }
  &--active > path {
    color: #{$primary} !important;
  }

  &__serialize-view-mode-toggle {
    .btn {
      &:hover,
      &:focus,
      &:active {
        background: none !important;
      }
    }
  }

  .accordion-header {
    display: flex;
    /* Use flexbox for layout */
    align-items: center;
    /* Align items vertically in the center */
  }

  .accordion-header h2 {
    flex-grow: 1;
    /* Allow h2 to take up remaining space */
    overflow: hidden;
    /* Hide overflow if h2 content is too long */
    text-overflow: ellipsis;
    /* Add ellipsis (...) to indicate overflow */
    white-space: nowrap;
    /* Prevent h2 content from wrapping */
  }

  .accordion-button {
    padding: 0;

    &::after {
      display: none !important;
    }
    &:active,
    &:focus,
    &:not(.collapsed) {
      background-color: transparent !important;
      outline: none !important;
      box-shadow: none !important;
    }
    &:not(.collapsed) {
      .fa-chevron-down {
        transform: rotate(180deg);
      }
    }

    .fa-chevron-down {
      margin-top: 0.5rem;
      font-size: 1rem !important;
      transition: transform 0.3s ease-in-out;
      color: $secondary;
    }
  }

  .accordion-body {
    padding: 0;
  }

  .nuggets svg {
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
  }

  .nugget-svg-wrapper {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }
}
