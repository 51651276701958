html,
body {
  /* Without this, dragging an element will scroll the page horizontally without showing a scrollbar, so it breaks the page display. */
  overflow-x: hidden;
}

/**
 * Padding in mark elements will mess up the display of e.g. headlines.
 */
mark {
  padding: 0;
}

@keyframes warning-fade {
  from {
    background: #{$warning};
  }

  to {
    background: #fff;
  }
}

.flash-warning {
  background: #fff;
  animation: warning-fade 0.5s ease-in-out 0s;
}

// TODO: Take from react-components
.CircularProgressbar {
  &.variant-none {
    .CircularProgressbar-path {
      stroke: white;
    }
  }

  &.variant-danger {
    .CircularProgressbar-path {
      stroke: #{$danger};
    }
  }

  &.variant-warning {
    .CircularProgressbar-path {
      stroke: #{$warning};
    }
  }

  &.variant-success {
    .CircularProgressbar-path {
      stroke: #{$success};
    }
  }

  &.variant-dark {
    .CircularProgressbar-path {
      stroke: #{$dark};
    }
  }
}

.btn {
  &.btn-primary {
    --bs-btn-color: #{map.get($theme-complementary-colors, 'primary')};
    --bs-btn-hover-color: #{map.get($theme-complementary-colors, 'primary')};
    --bs-btn-active-color: #{map.get($theme-complementary-colors, 'primary')};
    --bs-btn-disabled-color: #{map.get($theme-complementary-colors, 'primary')};
  }
}

.form-control,
.form-select,
.form-check-input {
  box-shadow: none !important;
}

.nav-link {
  border: none !important;
}

.tab-pane {
  > .content-box {
    border-top-left-radius: 0 !important;
  }
}

.badge {
  &.bg-light {
    --bs-badge-color: #{map.get($theme-complementary-colors, 'light')};
  }
}

.btn:not(:disabled):not(.disabled):not(.button-checkbox):not(.shadow):not(.shadow-sm) {
  box-shadow: none;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    box-shadow: none;
  }
}

.btn:not(:disabled):not(.disabled):not(.button-checkbox) {
  &.bg-white {
    background: linear-gradient(180deg, #fff, #fcfcfc) !important;

    &:hover {
      /*background: linear-gradient(180deg, #fff, #fff) !important;*/
    }
  }

  &.btn-primary {
    background: linear-gradient(180deg, #{lighten($primary, 3%)}, #{$primary});

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      /*background: linear-gradient(180deg, #{lighten($primary, 4%)}, #{lighten($primary, 2%)});*/
      color: white;
    }
  }

  &.btn-light,
  &.btn-dark,
  &.btn-danger {
    background: linear-gradient(180deg, #fff, #fcfcfc);
    border: none;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      /*background: linear-gradient(180deg, #fff, #fff) !important;*/
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  &.btn-light {
    color: #{$primary};

    > svg {
      color: #{$primary} !important;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: #{shift-color($primary, 5 * $theme-color-interval)};

      > svg {
        color: #{shift-color($primary, 5 * $theme-color-interval)} !important;
      }
    }

    &.color-dark {
      color: #{$dark};

      > svg {
        color: #{$dark} !important;
      }
    }

    &.btn-light-alt {
      background: #{$light};
      color: #{$dark};
      font-weight: bold;

      > svg {
        color: #{$dark} !important;
      }

      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        color: #{shift-color($primary, 5 * $theme-color-interval)};

        > svg {
          color: #{shift-color($primary, 5 * $theme-color-interval)} !important;
        }
      }
    }
  }

  &.btn-dark {
    color: #{$light};

    > svg {
      color: #{$light} !important;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: #{shift-color($light, 5 * $theme-color-interval)};

      > svg {
        color: #{shift-color($light, 5 * $theme-color-interval)} !important;
      }
    }
  }

  &.btn-danger {
    color: #{$danger};

    > svg {
      color: #{$danger} !important;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: #{shift-color($danger, 5 * $theme-color-interval)};

      > svg {
        color: #{shift-color($danger, 5 * $theme-color-interval)} !important;
      }
    }
  }

  &.bg-lighter {
    background: #{$lighter} !important;
  }

  &.bg-hover-light {
    &:hover {
      background: #{$light} !important;
    }
  }
}

.btn:disabled,
.btn.disabled {
  &.btn-light,
  &.btn-dark,
  &.btn-danger {
    background: linear-gradient(180deg, #fff, #fcfcfc);
    border: none;
  }
}

mark {
  background: #{$warning};
  color: white;
}

.bg-lighter {
  background: #{$lighter};
}

.bg-hover-light {
  &:hover {
    background: #{$light} !important;
  }
}

.bg-white-selectable {
  background: linear-gradient(180deg, #fff, #fcfcfc);

  &:hover {
    /*background: linear-gradient(180deg, #fff, #fff);*/
  }
}

.bg-primary-selectable {
  background: linear-gradient(180deg, #{lighten($primary, 2%)}, #{$primary});

  &:hover {
    /*background: linear-gradient(180deg, #{lighten($primary, 4%)}, #{lighten($primary, 2%)});*/
  }
}

.btn,
.bg-white-selectable,
.bg-primary-selectable {
  transition: all 0.3s;
}

.border-start-2 {
  border-left: 2px var(--bs-border-style) var(--bs-border-color) !important;
}

.border-light-lighter {
  border-color: #{$light-lighter} !important;
}

.border-lighter {
  border-color: #{$lighter} !important;
}

$react-dates-00a699: $primary;
$react-dates-008489: $primary;
$react-dates-ffe8bc: shift-color($primary, -5 * $theme-color-interval);
$react-dates-ffce71: shift-color($primary, -2 * $theme-color-interval);
$react-dates-66e2da: shift-color($primary, -2 * $theme-color-interval);
$react-dates-33dacd: shift-color($primary, -2 * $theme-color-interval);
$react-dates-b2f1ec: shift-color($primary, -4 * $theme-color-interval);
$react-dates-80e8e0: shift-color($primary, -4 * $theme-color-interval);
$react-dates-007a87: $primary;

body {
  /* general input style */
  .SingleDatePickerInput__withBorder {
    border-radius: 5px;
    border-color: #ced4da;
  }

  .DateInput {
    background: none !important;
  }

  .DateInput_input {
    font-size: 16px;
    padding: 5px;
    margin: 0 0 0 5px;
  }

  .SingleDatePickerInput_clearDate {
    width: 30px;
    height: 30px;
    padding: 5px;
  }

  /* colors */
  .PresetDateRangePicker_button {
    border: 2px solid $react-dates-00a699;
    color: $react-dates-00a699;
  }

  .PresetDateRangePicker_button__selected {
    background: $react-dates-00a699;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid $react-dates-00a699;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid $react-dates-008489;
  }

  .DayPickerKeyboardShortcuts_show__topRight::before {
    border-right: 33px solid $react-dates-00a699;
  }

  .DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid $react-dates-008489;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid $react-dates-00a699;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid $react-dates-008489;
  }

  .DayPickerKeyboardShortcuts_show__topRight::before {
    border-right: 33px solid $react-dates-00a699;
  }

  .DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid $react-dates-008489;
  }

  .DayPickerKeyboardShortcuts_show__topLeft::before {
    border-left: 33px solid $react-dates-00a699;
  }

  .DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid $react-dates-008489;
  }

  .CalendarDay__highlighted_calendar {
    background: $react-dates-ffe8bc;
  }

  .CalendarDay__highlighted_calendar:active,
  .CalendarDay__highlighted_calendar:hover {
    background: $react-dates-ffce71;
  }

  .CalendarDay__selected_span {
    background: $react-dates-66e2da;
    border: 1px double $react-dates-33dacd;
  }

  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: $react-dates-33dacd;
    border: 1px double $react-dates-33dacd;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: $react-dates-00a699;
    border: 1px double $react-dates-00a699;
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: $react-dates-b2f1ec;
    border: 1px double $react-dates-80e8e0;
    color: $react-dates-007a87;
  }

  .CalendarDay__hovered_span:active {
    background: $react-dates-80e8e0;
    border: 1px double $react-dates-80e8e0;
    color: $react-dates-007a87;
  }

  .DateInput_input__focused {
    border-bottom: 2px solid $react-dates-008489;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.hover-highlight {
  &:hover {
    background-color: #{shift-color($primary, 1 * $theme-color-interval)};
    color: white;
  }
}

.hover-shadow {
  box-shadow: none;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
}

.shadow-border {
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1) !important;

  .shadow-border-icon {
    box-shadow: 2px 0 0.25rem rgba(0, 0, 0, 0.1) !important;
  }
}

.shadow-border-sm {
  box-shadow: 0 0 0.15rem rgba(0, 0, 0, 0.05) !important;
}

.tooltip-wide {
  .tooltip-inner {
    max-width: 400px;

    ul {
      margin: 0;
      padding-left: 15px;

      li {
        margin: 0;
        text-align: left;
      }
    }
  }
}

.react-date-picker__wrapper {
  padding: 4px 8px;
  border-radius: 4px;
}

.flex-1-1 {
  flex: 1 1;
}

body {
  margin: 0;
  padding: 0;

  &.size-page {
    min-height: 100vh;
    height: 100%;
  }

  &.size-line {
    max-height: 32px;
    height: auto;
    overflow: hidden;
  }

  &.page-pull-dashboard,
  &.page-syndication-dashboard {
    background: #f4f4f4;
  }
}

.page-background {
  background: #f4f4f4;
}

.bg-none {
  background: none !important;
}

.content.container {
  margin: 0;
}

h1,
.h1 {
  font-weight: bold;
}

.inner-shadow {
  box-shadow: inset 0px 0px 6px rgb(0 0 0 / 10%);
}

.paged-syndication-list-item {
  &:hover {
    .paged-syndication-list-item-toggle {
      color: #{$primary};
    }
  }
}

@mixin modal-fullscreen() {
  padding: 0 !important; // override inline padding-right added from js

  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .modal-fullscreen#{$infix} {
      @include modal-fullscreen();
    }
  }
}

.user-html {
  img {
    max-width: 100%;
    max-height: 300px;
  }
}

.mw-25 {
  max-width: 25%;
}

.alert {
  border-width: 0 0 0 5px;
  border-radius: 0;
  background: white;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0 0.15rem rgba(0, 0, 0, 0.05) !important;

  &.alert-danger {
    border-color: #{$danger};
    color: #{$danger};
  }

  &.alert-warning {
    border-color: #{$warning};
    color: #{$warning};
  }

  &.alert-success {
    border-color: #{$success};
    color: #{$success};
  }

  &.alert-primary {
    border-color: #{$primary};
    color: #{$primary};
  }

  &.alert-light {
    border-color: #{$dark};
    color: #{$dark};
  }
}

.btn-group > * {
  border-radius: 0;

  &:not(.dropdown-toggle) {
    border-width: 0 !important;
  }

  &:first-child {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  &:last-child {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}

.btn:not(:disabled):not(:disabled):not(.button-checkbox) {
  &.btn-primary {
    font-weight: bold;
  }

  &.btn-danger,
  &.btn-danger:hover,
  &.btn-danger:active,
  &.btn-danger:focus,
  &.btn-danger:active:focus {
    border: 3px solid #{$danger};
    border-radius: 9px;

    text-transform: uppercase;
    font-weight: bold;
  }

  &.btn-warning,
  &.btn-warning:hover,
  &.btn-warning:active,
  &.btn-warning:focus,
  &.btn-warning:active:focus {
    border: 3px solid #{$warning};
    border-radius: 9px;
  }
}

body .btn:not(:disabled):not(.disabled):not(.shadow):not(.shadow-sm) {
  box-shadow: none !important;
}

body .btn:not(:disabled):not(.disabled) {
  border: none;
}

body a {
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: #{shift-color($primary, -3 * $theme-color-interval)};
  }
}

.op-hover {
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
}

/**
 * react-json-view-lite
 */
.json-viewer-collapse-icon,
.json-viewer-expand-icon {
  display: inline-block;
  margin: 0 0 0 -16px;
  width: 16px;
}
