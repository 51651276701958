//@import '~@edgebox/react-components/src/content-sync';

$primary: #009ee3;
$secondary: #999999;
$success: #00e370;
$info: #009ee3;
$warning: #e38600;
$danger: #e30000;
$light: #d4d4d4;
$light-lighter: #f2f2f2;
$lighter: #f8f8f8;
$dark: #616161;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);

$theme-complementary-colors: (
  'primary': #ffffff,
  'secondary': #000000,
  'success': #000000,
  'info': #000000,
  'warning': #000000,
  'danger': #ffffff,
  'light': #444444,
  'dark': #ffffff,
);

// Abstract
$component-active-bg: map.get($theme-colors, 'primary') !default;
$component-active-color: map.get($theme-complementary-colors, 'primary') !default;
$success: map.get($theme-colors, 'success') !default;
$danger: map.get($theme-colors, 'danger') !default;

// Navigation
$link-color: map.get($theme-colors, 'primary') !default;
$nav-pills-link-active-bg: map.get($theme-colors, 'primary') !default;

// Forms
$input-focus-border-color: map.get($theme-colors, 'primary') !default;
//$form-check-input-bg: map.get($theme-colors, "primary") !default;

$theme-color-interval: 0.1 !default;

@import '~bootstrap/scss/bootstrap';
@import '~@edgebox/react-components/src/simplistic.scss';

// Used for react-select components (e.g. Flow selector)
:root {
  --primary: #{$primary};
  --danger: #{$danger};
}
